import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface GenericHeaderProps {
  title: string;
  text: string;
  imagePath: string;
  supportButtons?: React.ReactNode;
}

export const GenericHeader = ({
  title,
  text,
  imagePath,
  supportButtons,
}: Readonly<GenericHeaderProps>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '300px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          alignSelf: 'flex-end',
        }}
      >
        {supportButtons}
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              maxWidth: '50%',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontFamily: '"MB Corpo A Text Condensed"',
                fontSize: '48px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '56px',
              }}
            >
              {title}
            </Typography>
            <Box sx={{ marginTop: '16px' }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'MB Corpo S Text',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '24px',
                }}
              >
                {text}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              alignSelf: 'flex-end',
              marginTop: '20px',
              marginRight: '45px',
            }}
          >
            <img src={imagePath} alt={`Header for ${title}`} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
