function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const getValue = (obj: T, key: keyof T) => {
    const value = obj[key];
    if (typeof value === 'string') {
      return value.toLowerCase();
    }
    return value;
  };

  const aValue = getValue(a, orderBy);
  const bValue = getValue(b, orderBy);

  if (!bValue) return -1;
  if (!aValue) return 1;
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key,
): (
  a: { [key in Key]: number | string | null },
  b: { [key in Key]: number | string | null },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
