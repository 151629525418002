import {
  EntityKindFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { useEffect, useMemo, useState } from 'react';
import { useAllKinds } from './useAllKinds';

export function useEntityKindFilter(opts: { initialFilter: string }): {
  loading: boolean;
  error?: Error;
  allKinds: string[];
  selectedKind: string;
  setSelectedKind: (kind: string) => void;
} {
  const {
    filters,
    queryParameters: { kind: kindParameter },
    updateFilters,
  } = useEntityList();

  const queryParamKind = useMemo(
    () => [kindParameter].flat()[0],
    [kindParameter],
  );

  const [selectedKind, setSelectedKind] = useState(
    queryParamKind ?? filters?.kind?.value ?? opts.initialFilter,
  );

  // Set selected kinds on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamKind) {
      setSelectedKind(queryParamKind);
    }
  }, [queryParamKind]);

  // Set selected kind from filters; this happens when the kind filter is
  // updated from another component
  useEffect(() => {
    if (filters?.kind?.value) {
      setSelectedKind(filters?.kind?.value);
    }
  }, [filters?.kind]);

  useEffect(() => {
    updateFilters({
      kind: selectedKind
        ? new EntityKindFilter(selectedKind, 'Kind')
        : undefined,
    });
  }, [selectedKind, updateFilters]);

  const { allKinds, loading, error } = useAllKinds();

  return {
    loading,
    error,
    allKinds: allKinds ?? [],
    selectedKind,
    setSelectedKind,
  };
}
