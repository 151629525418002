import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import { PlatformFeatureFlagged } from '@mb.io/plugin-platform-feature-flag';
import { SIPCentralIcon } from '@mercedes-benz/sip-central';
import { SidebarSettings } from '@mercedes-benz/user-settings';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import Apps from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import MenuBook from '@mui/icons-material/MenuBook';
import MapIcon from '@mui/icons-material/MyLocation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SecuritySharpIcon from '@mui/icons-material/SecuritySharp';
import StorefrontIcon from '@mui/icons-material/Storefront';
import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { SearchModal } from '../search/SearchModal';
import { SidebarSearchModal } from '../search/SidebarSearchModal';
import { MBLogo } from './MBLogo';
import CloudIcon from '@mui/icons-material/Cloud';
import InsightsIcon from '@mui/icons-material/Insights';
import { SidebarSubmenuDivider } from '@mb.io/core-components';
import TerminalIcon from '@mui/icons-material/Terminal';
import { AppTitle } from './AppTitle';
import HandymanIcon from '@mui/icons-material/Handyman';
import WebhookIcon from '@mui/icons-material/Webhook';
import Box from '@mui/material/Box';

const useSidebarLogoStyles = makeStyles()({
  root: {
    width: '100%',
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
  },
});

const useStyles = makeStyles()({
  sideBarLink: {
    '&:hover': {
      textDecoration: 'none',
    },
    '.MuiButton-label div:last-child': {
      width: 'auto',
      marginRight: '1em',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflowY: 'auto',
    padding: '0',
    margin: '0',
  },
});
const SidebarLogo = () => {
  const { classes } = useSidebarLogoStyles();

  return (
    <div className={classes.root}>
      <Link component={NavLink} to="/" underline="none">
        <MBLogo />
      </Link>
    </div>
  );
};

const FlaggedShortcuts = () => (
  <PlatformFeatureFlagged feature="shortcuts">
    <Shortcuts />
  </PlatformFeatureFlagged>
);

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { classes } = useStyles();

  // Add workaround so that SidebarSubmenu items open in the Safari browser when the window height is small.
  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
  };

  const sidebarContent = (
    <>
      <SidebarLogo />
      <AppTitle />
      <SidebarSearchModal>
        {({ toggleModal }) => <SearchModal toggleModal={toggleModal} />}
      </SidebarSearchModal>
      <SidebarDivider />
      {/* Global nav, not org-specific */}
      <SidebarItem
        icon={HomeIcon as never}
        to="/home"
        text="Home"
        data-testid="home-button"
      />
      <PlatformFeatureFlagged feature="catalogHome">
        <SidebarItem
          title="Catalog"
          icon={Apps as never}
          text="New Catalog"
          className={classes.sideBarLink}
          data-testid="catalog-button"
        >
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              icon={useApp().getSystemIcon('kind:domain')}
              to="/catalog-home/owned?filters[kind]=domain"
              title="Domain"
              data-testid="catalog-home-domain-button"
            />
            <SidebarSubmenuItem
              icon={useApp().getSystemIcon('kind:system')}
              to="/catalog-home/owned?filters[kind]=system"
              title="Systems"
              data-testid="catalog-home-system-button"
            />
            <SidebarSubmenuItem
              icon={useApp().getSystemIcon('kind:component')}
              to="/catalog-home/owned?filters[kind]=component"
              title="Component"
              data-testid="catalog-home-component-button"
            />
            <PlatformFeatureFlagged feature="seamless">
              <SidebarSubmenuItem
                icon={useApp().getSystemIcon('kind:seamless')}
                to="/catalog-home/owned?filters[kind]=seamless"
                title="Seamless"
                data-testid="catalog-home-seamless-button"
              />
            </PlatformFeatureFlagged>
            <SidebarSubmenuItem
              icon={useApp().getSystemIcon('kind:api')}
              to="/catalog-home/owned?filters[kind]=api"
              title="APIs"
              data-testid="catalog-apis-button"
            />
            <SidebarSubmenuDivider />
            <SidebarSubmenuItem
              title="Infrastructure"
              to="/catalog-home/owned?filters[kind]=infrastructure"
              icon={useApp().getSystemIcon('kind:infrastructure')}
              data-testid="catalog-home-infrastructure-button"
            />
            <SidebarSubmenuDivider />
            <SidebarSubmenuItem
              title="Groups"
              to="/catalog-home/all?filters[kind]=group"
              icon={useApp().getSystemIcon('kind:group')}
              data-testid="catalog-home-groups-button"
            />
            <SidebarSubmenuItem
              title="Users"
              to="/catalog-home/all?filters[kind]=user"
              icon={useApp().getSystemIcon('kind:user')}
              data-testid="catalog-home-users-button"
            />
          </SidebarSubmenu>
        </SidebarItem>
      </PlatformFeatureFlagged>
      <SidebarItem
        icon={Apps as never}
        text="Catalog"
        className={classes.sideBarLink}
        data-testid="catalog-button"
      >
        <SidebarSubmenu title="Catalog">
          <SidebarSubmenuItem
            title="Domains"
            to="/catalog?filters[kind]=domain"
            icon={useApp().getSystemIcon('kind:domain')}
            data-testid="catalog-domain-button"
          />
          <SidebarSubmenuItem
            title="Systems"
            to="/catalog?filters[kind]=system"
            icon={useApp().getSystemIcon('kind:system')}
            data-testid="catalog-systems-button"
          />
          <SidebarSubmenuItem
            title="Components"
            to="/catalog?filters[kind]=component"
            icon={useApp().getSystemIcon('kind:component')}
            data-testid="catalog-components-button"
          />
          <PlatformFeatureFlagged feature="seamless">
            <SidebarSubmenuItem
              icon={useApp().getSystemIcon('kind:seamless')}
              to="/seamless"
              title="Seamless"
              data-testid="catalog-seamless-button"
            />
          </PlatformFeatureFlagged>
          <SidebarSubmenuItem
            icon={useApp().getSystemIcon('kind:api')}
            to="/api-docs"
            title="APIs"
            data-testid="catalog-apis-button"
          />
          <SidebarSubmenuDivider />
          <SidebarSubmenuItem
            title="Infrastructure"
            to="/catalog?filters[kind]=infrastructure"
            icon={useApp().getSystemIcon('kind:infrastructure')}
            data-testid="catalog-infrastructure-button"
          />
          <SidebarSubmenuDivider />
          <SidebarSubmenuItem
            title="Groups"
            to="/catalog?filters[kind]=group"
            icon={useApp().getSystemIcon('kind:group')}
            data-testid="catalog-groups-button"
          />
          <SidebarSubmenuItem
            title="Users"
            to="/catalog?filters[kind]=user"
            icon={useApp().getSystemIcon('kind:user')}
            data-testid="catalog-users-button"
          />
        </SidebarSubmenu>
      </SidebarItem>
      <SidebarItem
        icon={LibraryBooks as never}
        to="/docs"
        text="Docs"
        data-testid="documentation-button"
      />
      <SidebarItem
        icon={MenuBook as never}
        to="/esg"
        text="Guidelines"
        data-testid="guidelines-button"
      />
      <SidebarItem
        icon={SchoolRoundedIcon as never}
        to="/academy"
        text="Academy"
        data-testid="academy-button"
      />
      <SidebarItem
        icon={LayersIcon as never}
        to="/explore"
        text="Explore"
        data-testid="explore-button"
      />
      <SidebarItem
        icon={CreateComponentIcon as never}
        to="/create"
        text="Create..."
        data-testid="create-button"
      />
      <SidebarDivider />
      <SidebarItem
        icon={CloudIcon as never}
        text="cloudX"
        className={classes.sideBarLink}
        data-testid="cloudx-button"
      >
        <SidebarSubmenu title="cloudX">
          <PlatformFeatureFlagged feature="tdms-next">
            <SidebarSubmenuItem
              subtitle="Provided by Nebula"
              icon={EngineeringIcon as never}
              to="/tdms-next"
              title="TDMS"
              data-testid="tdms-next-button"
            />
          </PlatformFeatureFlagged>
          <SidebarSubmenuItem
            subtitle="Provided by Nebula"
            icon={InsightsIcon as never}
            to="/pmas"
            title="PMAS"
            data-testid="pmas-button"
          />
          <SidebarSubmenuDivider />
          <SidebarSubmenuItem
            subtitle="Provided by HELIOS"
            icon={SIPCentralIcon}
            to="/sip-central"
            title="HELIOS Central"
            data-testid="sip-central-button"
          />
          <PlatformFeatureFlagged feature="seccomp">
            <SidebarSubmenuItem
              subtitle="Provided by HELIOS"
              icon={SecuritySharpIcon as never}
              to="/seccomp"
              title="Compliance"
              data-testid="seccomp-button"
            />
          </PlatformFeatureFlagged>
        </SidebarSubmenu>
      </SidebarItem>
      <SidebarItem
        icon={HandymanIcon as never}
        text="Tools"
        className={classes.sideBarLink}
        data-testid="tools-button"
      >
        <SidebarSubmenu title="Tools">
          <SidebarSubmenuItem
            title="DWS"
            to="/dws"
            icon={WebhookIcon as never}
            data-testid="dws-button"
          />
        </SidebarSubmenu>
      </SidebarItem>
      <SidebarItem
        icon={MapIcon as never}
        to="/tech-radar"
        text="Tech Radar"
        data-testid="tech-radar-button"
      />
      <SidebarItem
        icon={StorefrontIcon as never}
        to="/bazaar"
        text="Bazaar"
        data-testid="bazaar-button"
      />
      {/* End global nav */}
      <FlaggedShortcuts />
      <SidebarSpace />
      <SidebarDivider />
      <SidebarSettings />
      <RequirePermission
        permission={devToolsAdministerPermission}
        errorPage={<></>}
      >
        <SidebarItem
          icon={TerminalIcon as never}
          to="/devtools"
          text="DevTools"
          data-testid="devtools-button"
        />
      </RequirePermission>
    </>
  );

  return (
    <SidebarPage>
      <Sidebar disableExpandOnHover>
        {isSafari() && (
          <Box className={isSafari() ? classes.container : ''}>
            {sidebarContent}
          </Box>
        )}
        {!isSafari() && sidebarContent}
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
