import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import CardActionArea from '@mui/material/CardActionArea';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '2px',
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    height: '100%',
  },
}));

export interface EntityCardViewProps {
  content: React.ReactNode;
  action: () => void;
}

export const EntityCard = (props: Readonly<EntityCardViewProps>) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <CardActionArea
        disableRipple
        className={classes.cardActionArea}
        onClick={() => props.action()}
        data-testid="card-element"
      >
        <CardContent className={classes.cardContent}>
          {props.content}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
